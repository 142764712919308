@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  font-family: "Poppins", sans-serif !important;
}
code {
  font-family: "Poppins", sans-serif;
}

.searchbar.MuiInputBase-input {
  height: 0.3em !important;
}
.searchbar .MuiInputBase-formControl {
  height: 2.3em !important;
  border: none;
  border-radius: 0px;
}
.MuiOutlinedInput-notchedOutline {
  /* height: 100%; */
  /* border: none !important; */
}

.btn:hover {
  /* display: none !important; */
}
.MuiFormControlLabel-label {
  font-size: 14px !important;
}

.btn:hover:hover {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  background-color: none !important;
}
.btn:hover {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  background-color: none !important;
}

.areaText::placeholder {
  padding-left: 1em;
  padding-top: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 16px;
}
.react-datepicker::after ::before {
  display: none !important;
}

button {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  background-color: none !important;
}

.section-event {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 23px -7px #ccc !important;
  padding: 2em;
}

.event-head {
  font-size: 20px !important;
  color: #2d55eb;
}

.accordion-header .accordion-button:not(.collapsed) {
  background-color: #f7f7f7 !important;
}
.accordion-header .accordion-button {
  background-color: #f7f7f7 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  border: 1px solid #989898;
  border-radius: 0em !important;
}
.head {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin: 1em 0em;
}
.head:after {
  content: "";
  display: block;
  width: 41em;
  height: 1px;
  background: #aaaaaa;
  right: -17.9em;
  top: 50%;
  position: absolute;
}
.head1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin: 1em 0em;
}
.head1:after {
  content: "";
  display: block;
  width: 46em;
  height: 1px;
  background: #aaaaaa;
  right: -23.4em;
  top: 50%;
  position: absolute;
}
.heading1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.heading1:after {
  content: "";
  display: block;
  width: 46em;
  height: 1px;
  background: #aaaaaa;
  right: -23.4em;
  top: 50%;
  position: absolute;
}
.heading {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.heading:after {
  content: "";
  display: block;
  width: 38em;
  height: 1px;
  background: #aaaaaa;
  right: -17.9em;
  top: 50%;
  position: absolute;
}
.headings {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  /* margin-bottom: 2em; */
  margin-top: 2em;
}
.headings h6 {
  font-size: 12px !important;
}

.headings:after {
  content: "";
  display: block;
  width: 36em;
  height: 1px;
  background: #aaaaaa;
  right: -17.9em;
  top: 50%;
  position: absolute;
}
.time {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.time:after {
  content: "";
  display: block;
  width: 43.7em;
  height: 1px;
  background: #aaaaaa;
  right: -17.9em;
  top: 50%;
  position: absolute;
}
.time1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.time1:after {
  content: "";
  display: block;
  width: 44.7em;
  height: 1px;
  background: #aaaaaa;
  right: -23.4em;
  top: 50%;
  position: absolute;
}
.market {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.market:after {
  content: "";
  display: block;
  width: 43.7em;
  height: 1px;
  background: #aaaaaa;
  right: -23.4em;
  top: 50%;
  position: absolute;
}
.message {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.message:after {
  content: "";
  display: block;
  width: 47.7em;
  height: 1px;
  background: #aaaaaa;
  right: -23.4em;
  top: 50%;
  position: absolute;
}
.datepicker input {
  border-radius: 0px;
  color: #3f4043;
  background-color: #f2f2f24d;
}
.tableHead th {
  font-size: 12px;
  font-weight: 500;
  background-color: #ffc400 !important;
  text-align: center;
}
.tableHead td {
  font-size: 12px;
  font-weight: 500;
}

.tableHead tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: groove !important;
  border-width: 0;
}
.css-9640we-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

/* banner css  */

.Banner-container {
  /* background-color: #06228a; */
  background-image: url(./assets/common/banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 23em;
}
.Buttons {
  float: right;
  margin: auto;
}
@media (max-width: 767px) {
  .Banner-container {
    /* background-color: #06228a; */
    background-image: url(./assets/common/banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 35em;
  }
  .mobSideMenu {
    width: 50% !important;
  }
  .dashMenu .mobMenu {
    /* width: 33.3333%; */
    width: 50% !important;
    padding-bottom: 1em;
  }
  .dashMenu {
    justify-content: center;
  }
  .Buttons {
    float: none !important;
    text-align: center;
    margin-top: 1em;
  }
  .twitter {
    width: 100% !important;
  }
  .googleIcon > div {
    width: 100% !important;
  }
  .tabletSection {
    width: 100% !important;
    margin-bottom: 1em !important;
  }
  .logo {
    text-align: center;
  }
  .alignCenter {
    text-align: center;
    justify-content: center;
  }
  .mob-card {
    width: 100% !important;
  }
  .mobContact {
    width: 50% !important;
  }
  .uploader {
    margin-top: 1em;
  }
  .message:after {
    content: "";
    display: block;
    width: 9.7em;
    height: 1px;
    background: #aaaaaa;
    right: 0.1em;
    top: 50%;
    position: absolute;
  }
  .head1:after {
    content: "";
    display: block;
    width: 8em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .heading1:after {
    content: "";
    display: block;
    width: 8em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .head:after {
    content: "";
    display: block;
    width: 8em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .heading:after {
    content: "";
    display: block;
    width: 2em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .time1:after {
    content: "";
    display: block;
    width: 7em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .time:after {
    content: "";
    display: block;
    width: 9em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .market:after {
    content: "";
    display: block;
    width: 6.2em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .headings:after {
    content: "";
    display: block;
    width: 4em;
    height: 1px;
    background: #aaaaaa;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .mob-margin {
    margin-top: 1em !important;
  }
}

.arrow {
  transform: rotate(90deg);
  color: #2d55eb;
}

/* ronak css pages  */
.auctionContainer {
  background-color: #f8f9ff;
}

.imageText {
  height: 8em;
  width: 100%;
  object-fit: contain;
  background-color: #d1e1f0;
}

.imageSection .auctionContent {
  padding-top: 0;
  height: 2em;
  border: 2px solid red !important;
}

.MuiGrid-item {
  padding-top: 0 !important;
}

.alertContainer {
  margin: 2rem 0;
}

.alert {
  margin-bottom: 0;
}

.alert p {
  margin: 0;
}

.biddingTimings {
  /* display: flex; */
  text-align: center !important;
  /* justify-content: space-between !important; */
}

.imageContainer image {
  object-fit: contain !important;
}

.lastBid {
  font-weight: bold;
  color: #7e8184;
  font-size: small;
}

.carousel-indicators {
  display: none;
}

.carousel {
  box-shadow: 0px 1px 6px 0px rgb(221, 219, 219);
  background-color: #e4e4e4;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-size: large !important;
  font-weight: bold !important;
  color: #2d55eb !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #2d55eb !important;
}

.auctionTab .css-19kzrtu {
  padding: 24px 0px 0px;
}

.carousel-indicators {
  display: none !important;
}

.datePicker {
  width: 100% !important;
}

.datePicker .css-o5641v-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0em !important;
  height: 38px !important;
  line-height: 1.5;
  background-color: #f2f2f24d;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}
.datePicker .css-o5641v-MuiInputBase-root-MuiOutlinedInput-root:hover {
  border-color: #f2f2f24d !important;
}

.carousel-control-prev-icon {
  background-image: url(../src/assets/siderIcon/left.png) !important;
  background-color: #fff !important;
  border-radius: 100px !important;
  width: 1.4em !important;
  height: 1.4em !important;
  box-shadow: 0px 0px 5px 2px #ccc;
}

.carousel-control-next-icon {
  background-image: url(../src/assets/siderIcon/right.png) !important;
  background-color: #fff !important;
  border-radius: 100px !important;
  width: 1.4em !important;
  height: 1.4em !important;
  box-shadow: 0px 0px 5px 2px #ccc;
}

.carousel-control-prev {
  left: -16px !important;
}

.carousel-control-next {
  right: -16px !important;
}

.filterSearch {
  position: relative;
}
/* .homeContent{
  margin-top: 6.4em;
} */
.googleIcon > div > div {
  width: 40px !important;
  height: 34px !important;
}
.googleIcon > div {
  height: 36px !important;
  width: 100% !important;
}

.googleIcon > div > div svg {
  width: 40px !important;
  height: 34px !important;
}

.googleIcon span {
  font-size: 10px !important;
  line-height: 0px !important;
  position: absolute;
  left: 44px;
  top: 18px;
}
.googleIcon {
  position: relative;
}
.css-13ox5z9 {
  width: 100% !important;
}
.mainLogo {
  width: 4em;
}
.next {
  color: #fff !important;
  width: 14em !important;
  border-radius: 0em !important;
  background-color: #2d55eb !important;
}
.css-tzrizk {
  width: 100% !important;
}
@media only screen and (max-width: 768px) {
  .next {
    width: 100% !important;
    margin-bottom: 1em !important;
  }
  .userName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10em;
  }
  .mainCardimg {
    height: 20em;
    object-fit: cover !important;
  }
  .mainLogo {
    width: 3em;
    margin: 0;
    /* margin-left: 1.5em; */
  }
  .searchbar {
    margin-top: 1rem;
  }
  .firstText {
    color: #3f4043;
    font-size: small !important;
    font-weight: bold;
    margin-bottom: 0 !important;
    margin-top: 2em !important;
  }
  .logo {
    text-align: center !important;
  }
  .modalMain {
    width: 90% !important;
    background-color: #fff;
    box-shadow: none;
    padding: 32px;
    overflow-y: scroll !important;
    height: 100% !important;
    transform: translate(-50%, 4%) !important;
    top: 0 !important;
    left: 50% !important;
    background: transparent;
  }
  .mob {
    display: none !important;
  }
  .addlots {
    background-color: #e8edff !important;
    border-radius: 18px !important;
    margin-left: 1em !important;
    padding: 5px 10px !important;
    width: 40% !important;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .auctionListName {
    font-size: 10px !important;
  }
  .fontSetLotNumber {
    font-size: 10px !important;
  }
  .head1:after {
    width: 26em;
    right: -3.4em;
  }
  .heading1:after {
    width: 26em;
    right: -3.4em;
  }
  .time1:after {
    width: 24.7em;
    right: -3.4em;
  }
  .market:after {
    width: 24.7em;
    right: -3.5em;
  }
  .message:after {
    width: 27.7em;
    right: -3.4em;
  }
  .head:after {
    width: 24em;
    right: -0.9em;
  }
  .heading:after {
    width: 20.7em;
    right: -0.9em;
  }
  .headings:after {
    width: 18em;
    right: -0.9em;
  }
  .time:after {
    width: 25.7em;
    right: -0.9em;
  }
  .letter {
    font-size: 13px !important;
  }
  .dashMenu .mobMenu {
    width: 20%;
    padding-bottom: 0.4em;
  }
  /* .eventId {
    font-size: 13px !important;
  } */
  .tabHead {
    margin: 12em 0;
  }
  .tabletSection {
    width: 33.333333% !important;
  }
  .uploader {
    margin-top: 0px !important;
  }
  .mob-margin {
    margin-top: 0em !important;
  }
  .display {
    display: flex !important;
  }
  .avatar {
    width: 30px !important;
    height: 30px !important;
  }
  .avatar svg {
    width: 50% !important;
    height: 50% !important;
  }
  .mob-card-responsive {
    width: 50% !important;
  }
  .imageText {
    height: 7em;
    width: 100%;
    object-fit: contain;
    background-color: #d1e1f0;
  }
}

.view {
  border: none !important;
  border-radius: 0px !important;
  color: #000 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: 4px 0px !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.css-1mxz8qt-MuiPaper-root {
  border-radius: 0px !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.viewSelect {
  height: 38px;
  background-color: #e8edff !important;
  border-radius: 18px !important;
  color: #2d55eb !important;
}

.MuiTableSortLabel-icon {
  color: blue !important;
  opacity: 1;
}

/* .MuiTableSortLabel-icon {
  opacity: 1;
  color: red !important;
} */

.addlots {
  background-color: #e8edff !important;
  border-radius: 18px !important;
  margin-left: 1em !important;
  padding: 5px 25px;
  width: 18%;
  text-align: center;
}

.viewBuyer .css-19kzrtu {
  padding: 1em 0px !important;
}

.eventId {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 0em !important;
}

.imageText img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-item img {
  width: 100%;
  border-radius: 10px;
  margin-top: 1em;
  height: 10em;
  border: 1px solid #ccc;
  object-fit: cover;
}

/* .DataTable .MuiTableSortLabel-icon{
  opacity: 1;
  color: #2d55eb;
} */

.DataTable .rdt_TableHeadRow {
  background-color: #fcfcfc !important;
  color: #3f4043 !important;
}
.AuctionTable .DataTable .rdt_TableHeadRow {
  background-color: #fcfcfc !important;
  color: #3f4043 !important;
}
.DataTable {
  border: 1px solid #989898;
}

.AuctionTable .data-table-extensions > .data-table-extensions-filter {
  float: right;
}
.data-table-extensions > .data-table-extensions-filter {
  float: left;
}
.data-table-extensions-filter {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #a0a0a0;
  background-color: #f2f2f24d;
  padding: 0.1rem 1.2rem;
  margin-top: 0.5em;
}
.AuctionTable .data-table-extensions-filter {
  width: 30%;
  display: flex;
  flex-direction: row-reverse;
  border: 0.4000000059604645px solid #a0a0a0;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 0.1rem 1.2rem;
  margin-top: 0.5em;
  border-radius: 10px;
  margin-right: 1em;
}

.filter-text {
  width: 100%;
  border: none;
}
.data-table-extensions {
  padding: 0 !important ;
}
.AuctionTable .data-table-extensions {
  padding: 0 !important ;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  height: 35px !important;
}
.AuctionTable .data-table-extensions > .data-table-extensions-filter > .icon {
  height: 35px !important;
  color: red !important;
  opacity: 1;
}

.pagination
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: rgb(45 85 235) !important;
  border-radius: 100px !important;
  border: none !important;
  color: #fff !important;
}
.pagination MuiButtonBase-root MuiPaginationItem-root {
  background-color: #fff !important;
  border-radius: 100px !important;
  border: 1px solid #ccc !important;
  opacity: 1 !important;
}
.pagination
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  background-color: #fff !important;
  border-radius: 100px !important;
  border: 1px solid #ccc !important;
  opacity: 1 !important;
}

.AuctionTable h3 {
  /* position: absolute;
  top: 12px;
  left: 0px; */
  color: rgb(45 85 235);
  font-size: 22px;
}
.topDetail h3 {
  color: rgb(45 85 235);
  font-size: 22px;
}
.topDetail {
  margin-bottom: 1em;
}
.topDetail p {
  font-size: 12px !important;
}
.topDetail p span {
  font-weight: 600 !important;
}
.AuctionTable {
  position: relative;
}

.AuctionView .data-table-extensions {
  visibility: hidden !important;
  display: none;
}

.editRules .head {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin: 1em 0em;
}
.editRules .head:after {
  content: "";
  display: block;
  width: 36em;
  height: 1px;
  background: #aaaaaa;
  right: -12.2em;
  top: 50%;
  position: absolute;
}
.editRules .head1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin: 1em 0em;
}
.editRules .head1:after {
  content: "";
  display: block;
  width: 40em;
  height: 1px;
  background: #aaaaaa;
  right: -17.4em;
  top: 50%;
  position: absolute;
}
.editRules .heading1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .heading1:after {
  content: "";
  display: block;
  width: 39em;
  height: 1px;
  background: #aaaaaa;
  right: -17.4em;
  top: 50%;
  position: absolute;
}
.editRules .heading {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .heading:after {
  content: "";
  display: block;
  width: 33em;
  height: 1px;
  background: #aaaaaa;
  right: -12.4em;
  top: 50%;
  position: absolute;
}
.editRules .headings {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  /* margin-bottom: 2em; */
  margin-top: 2em;
}
.editRules .headings h6 {
  font-size: 12px !important;
}

.editRules .headings:after {
  content: "";
  display: block;
  width: 30em;
  height: 1px;
  background: #aaaaaa;
  right: -12.4em;
  top: 50%;
  position: absolute;
}
.editRules .time {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .time:after {
  content: "";
  display: block;
  width: 37em;
  height: 1px;
  background: #aaaaaa;
  right: -12.4em;
  top: 50%;
  position: absolute;
}
.editRules .time1 {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .time1:after {
  content: "";
  display: block;
  width: 38.7em;
  height: 1px;
  background: #aaaaaa;
  right: -17.4em;
  top: 50%;
  position: absolute;
}
.editRules .market {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .market:after {
  content: "";
  display: block;
  width: 37.7em;
  height: 1px;
  background: #aaaaaa;
  right: -17.4em;
  top: 50%;
  position: absolute;
}
.editRules .message {
  color: black;
  max-width: 600px;
  position: relative;
  color: #2d55eb;
  margin-bottom: 1em;
  margin-top: 1em;
}
.editRules .message:after {
  content: "";
  display: block;
  width: 41.7em;
  height: 1px;
  background: #aaaaaa;
  right: -17.4em;
  top: 50%;
  position: absolute;
}

.editviewBuyer .data-table-extensions {
  display: none;
}

.pagination .MuiPagination-ul {
  flex-wrap: nowrap;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0d6efd;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.loaderBg {
  background-color: #00000085;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 9999;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hoverClose:hover {
  background-color: rgb(45, 85, 235) !important;
  color: #fff !important;
}

.otp div {
  justify-content: space-around;
}

.otp input {
  width: 3em !important;
  border: none;
  height: 3em;
  box-shadow: 0px 0px 13px -3px #ccc;
  border-radius: 10px;
}

.otp span {
  display: none;
}

.DataTable-icon span {
  opacity: 1 !important;
}

.counter {
  color: green;
}

.letter {
  letter-spacing: 1.8px !important;
  font-size: 15px !important;
}

.fontLevel {
  /* padding: 2.4px !important; */
  font-size: 12px !important;
}

.colorBlack {
  color: #000 !important;
  font-size: 16px;
}

.download {
  background-color: #0d6efd !important;
  color: #fff !important;
  margin-right: 5px;
}
.downloadBTN {
  display: flex;
  justify-content: end;
}

.catelog {
  position: relative;
}

.catelog h5 {
  display: none;
  position: absolute;
  bottom: -18px;
  left: -30px;
  font-size: 14px;
}
.catelog:hover h5 {
  display: block;
}

.heightFix {
  height: 14.5em;
}

.privacyHead h2 {
  font-weight: 600;
}
.privacyHead {
  text-align: center;
}
.topContent {
  text-align: center;
  font-style: italic;
  /* font-size:14px; */
}

.heads h4 {
  font-size: 20px;
}
.termHead h2 {
  font-weight: 600;
  text-align: center;
}

.liveAuction .rdt_TableCol {
  background-color: #ffc400 !important;
}
.heads h4 {
  font-size: 20px;
}
.termHead h2 {
  font-weight: 600;
  text-align: center;
}

.aboutContent h2 {
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
}

.sellerContent h2 {
  text-align: center;
  margin-bottom: 1em;
  font-weight: 600;
}

.sellerContent {
  margin: 8em 0;
}

.howSell {
  text-align: center;
  margin-top: 4em;
}

.howSell .btn {
  width: 20em;
}

.mobSideMenu .btn {
  font-size: 13px !important;
}

/* .editModalBox{ 
} */

.modalDownloadButton {
  text-align: center;
  padding: 1rem 2rem;
}

.editDownloadButton {
  color: #fff;
  background-color: #0022a0;
  border: 2px solid #0022a0 !important;
  text-decoration: none;
  padding: 0.3rem 1rem;
  border-radius: 7px;
}

.editDownloadButton:hover {
  color: #0022a0;
  background-color: #fff;
  border: 2px solid #0022a0 !important;
}

.editUploadButton {
  color: #fff;
  background-color: #0022a0;
  border: 2px solid #0022a0 !important;
  text-decoration: none;
  padding: 0.3rem 1rem;
  border-radius: 7px;
}

.editUploadButton:hover {
  color: #0022a0;
  background-color: #fff;
  border: 2px solid #0022a0 !important;
}

.editModalBox input {
  border: 2px dashed #b4b5ba;
  width: 100%;
  display: block;
  padding: 3rem 7rem;
  text-align: center;
  margin: 1rem 0;
}

.editModalBox button {
  display: flex !important;
  justify-content: center !important;
}

.modalInsideForm {
  text-align: center !important;
}

.editListModal {
  transform: translate(0%, 15%);
}
.sellerColFirst {
  padding-left: 3.35rem !important;
}
.sellerColSecond {
  width: 33% !important;
}
.sellerColThird {
  padding-right: 1.7rem !important;
}

.dateSelector input {
  padding: 7px 14px !important;
}

.download-button-later {
  background-color: #0022a0 !important;
  border: 2px solid #0022a0 !important;
  color: #fff !important;
  font-weight: 900 !important;
}

.modalInsideForm .new-background {
  border: 2px dashed #b4b5ba !important;
  background-color: #fff !important;
  padding: 4rem !important;
}

@media (max-width: 767px) {
  .downloadBTN {
    justify-content: start;
    margin: 5px 0;
  }
  .downloadBTN button {
    margin: 0 10px !important;
  }
  .mobTopBar {
    margin: 0 !important;
  }
  .mobTopBar Button {
    font-size: 13px !important;
  }
  .saveButtonDisplay {
    /* display: flex !important; */
  }
  .mobScroll {
    width: 19em !important;
    margin: auto !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll;
    box-shadow: 2px 5px 4px #efeff2;
  }
  .sellerColFirst {
    padding: 0 1rem !important;
    width: 33% !important;
  }
  .sellerColSecond {
    width: 33% !important;
  }
  .sellerColThird {
    padding: 0 !important;
    width: 33% !important;
  }
  .mobileAmountClass {
    display: flex;
    justify-content: space-between;
  }
  .eventId {
    font-size: 13px !important;
  }
  .tabHead {
    margin: 6em 0;
  }
  .editModalBox input {
    padding: 3rem;
  }
  .editListModal {
    transform: translate(0%, 25%);
  }
  .editListModal .modal-body {
    padding: 20px 10px !important;
  }
  .drawerLogo {
    height: 2.5em;
    object-fit: contain;
    width: 100%;
  }
  .auctionCardRightMobile {
    width: 70% !important;
    margin-top: 5px !important;
  }
  .auctionCardLeftMobile {
    width: 30% !important;
    margin-top: 5px !important;
  }
  .bidMobileLive {
    width: 50% !important;
    margin: 0.6rem 0 0 0 !important;
  }
  .sellerContent {
    margin-top: 10em;
  }
  .section-event {
    padding: 1em !important;
  }
  .letter {
    font-size: 13px !important;
  }
  .auctionListName {
    font-size: 10px !important;
  }
  .editRules .head1:after {
    width: 5em;
    right: -1.4em;
  }
  .editRules .heading1:after {
    width: 4em;
    right: -1.4em;
  }
  .editRules .time1:after {
    width: 3.7em;
    right: -1.4em;
  }
  .editRules .market:after {
    width: 2.7em;
    right: -1.4em;
  }
  .editRules .message:after {
    width: 6.7em;
    right: -1.4em;
  }
  .editRules .head:after {
    width: 6em;
    right: -0.2em;
  }
  .editRules .heading:after {
    width: 3em;
    right: -0.2em;
  }
  .editRules .time:after {
    width: 9em;
    right: -0.2em;
  }
  .editRules .headings:after {
    width: 1em;
    right: -0.2em;
  }
}

.google-login {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0px;
  margin-top: 0.5em;
  text-decoration: none;
}
.google-login img {
  width: 1.5em;
}

.datePickers input {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  line-height: 1.5 !important;
  height: 1.6em !important;
}
.datePickers {
  width: 100%;
}
